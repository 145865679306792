<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Edit Salary Slab</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'salary-slabs'}">
                        <span>Salary Slabs</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Edit</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:keyup.enter="updateSalarySlab($event)">
                    <div class="form-group row">
                        <label class="col-md-3">
                            Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="text"
                                class="form-control"
                                aria-describedby="nameHelpInline"
                                v-model="salary_slab.name"
                                @change="textTitleCase($event, 'name')"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Minimum Salary
                        </label>
                        <div class="col-md-9">
                            {{salary_slab.min_salary}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Maximum Salary
                        </label>
                        <div class="col-md-9">
                            {{salary_slab.max_salary}}
                        </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="updateSalarySlab()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-secondary ma-10px"
                                @click="$router.go(-1)"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            access: false,
            salary_slab: {
                name: '',
                max_salary: '',
                min_salary: ''
            },
            alertDismissCountDown: 0,
            alertDismissSecs: 10
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_SALARY_SLABS");
        this.getSalarySlab();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.salary_slab[key] = this.strTitleCase(event.target.value);
        },

        getSalarySlab() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            let input = {
                id : this.$route.params.id
            }
            query["params"] = input;
            this.axios.get(this.$api.get_salary_slabs, query)
                .then(response => {
                    this.salary_slab.name = response.data.data.name;
                    this.salary_slab.min_salary = response.data.data.min_salary;
                    this.salary_slab.max_salary = response.data.data.max_salary;
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        updateSalarySlab() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            query["params"] = {};
            this.salary_slab['id'] = this.$route.params.id;
            this.axios.post(this.$api.update_salary_slab, this.salary_slab, query)
                .then(response => {
                    if (response.data.error) {
                        this.api_error = response.data.message;
                        this.showAlert();
                        this.updateProgressBar(true);
                        return;
                    }
                    this.$router.push({ name: "salary-slabs" });
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
